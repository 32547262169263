<template>
	<section id="selfdxNews" class="selfdx-section display-flex" :class="{ 'is-active': isVisible }" ref="viewport">
		<div class="container">
			<div class="selfdx-section-header">
				<h3 class="selfdx-section-header__title">다양한 정보를 볼 수 있는 <span class="color-orange">소상공인 뉴스</span></h3>
				<p class="selfdx-section-header__desc">주기적으로 제공되는 소상공인 뉴스를 살펴보세요. 온라인 판로 진출에 대한 최신 트렌드를 읽는데 도움이 됩니다.</p>
				<div class="selfdx-section-header__buttons">
					<a href="/sentcont/home" target="_blank" class="button-default is-medium is-rounded is-secondary">전체 뉴스 보기</a>
				</div>
			</div>
			<div class="selfdx-section-content">
				<ul class="card-list">
					<li class="card-item" v-for="(item, idx) in diagnNewsInfos" :key="idx">
						<a :href="item.newsLink" target="_blank" class="card-item__link">
							<div class="card-item__image">
								<img :src="item.newsThumbnail" :alt="item.newsTitle" />
							</div>
							<div class="card-item__category">
								{{ item.newsCategory }}
							</div>
							<div class="card-item__title">
								{{ item.newsTitle }}
							</div>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SelfDxResultNews',
	components: {},
	props: {
		diagnNewsInfos: {
			type: Array,
			default: [],
		},
	},
	data: () => ({
		isVisible: false,
		items: [
			{
				sentContId: 222,
				sentContTit: '2023년 반드시 체크해야할 세금 정보는? | 사장님을 위한 솔루션 위원회 EP.60',
				sentContSmlTit: '2023년 반드시 체크해야할 세금 정보는? | 사장님을 위한 솔루션 위원회 EP.60',
				sentContCn: null,
				sentContCtgrDcd: '1202001',
				sentContCtgrDcdVal: '정책동향',
				thmbnlImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/plygrnd/rprs/20535/2023/11/09/40f24fc7.jpg',
				thmbnlImgFileNm: null,
				inqCnt: 54,
				expsrYn: 'Y',
				useYn: null,
				rgtrId: null,
				regDt: 1672022695680,
				pstgBgngDt: '2024-05-22',
				preId: null,
				nextId: null,
			},
		],
	}),
	mounted() {
		this.$nextTick(() => {
			this.initializeIntrsObsrv();
		});
	},
	methods: {
		initializeIntrsObsrv() {
			const io = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach(async (entry) => {
						// 감지대상이 교차영역에 진입 할 경우
						if (entry.isIntersecting) {
							this.isVisible = true;
							// 교차영역에 진입한 후 감지대상을 관찰 중지
							observer.unobserve(entry.target);
						} else {
							this.isVisible = false;
						}
					});
				},
				{
					rootMargin: '0px',
					threshold: 0.1,
				}
			);
			const element = this.$refs.viewport;
			if (element) {
				io.observe(element);
			}
		},
	},
};
</script>
