<template>
	<section id="selfdxProduct" class="selfdx-section" :class="{ 'is-active': isVisible }" ref="viewport">
		<div class="selfdx-bg">
			<i class="icon icon-selfdx-bg5"></i>
		</div>
		<div class="container">
			<div class="selfdx-section-header">
				<h3 class="selfdx-section-header__title">사례분석에 도움이 되는 우수제품 소개</h3>
				<p class="selfdx-section-header__desc">
					유사한 분야의 상품 판매 사례를 살펴보세요.<br />
					판로지원사업을 통해 선정된 우수상품 벤치마킹을 통해 장단점을 분석 후 반영해 보세요.
				</p>
			</div>
			<div class="selfdx-section-content">
				<ul class="card-list">
					<li v-for="(item, idx) in diagnPrdutDomains" :key="idx" class="card-item">
						<button type="button" class="card-item__link" @click.prevent="openModal(item)">
							<div class="card-item__image">
								<img :src="item.imgUrl" :alt="item.name" />
							</div>
							<div class="card-item-body">
								<span class="card-item__company"> {{ item.business }}</span>
								<span class="card-item__title"> {{ item.name }}</span>
							</div>
						</button>
					</li>
				</ul>
			</div>
		</div>
		<!-- s: 팝업 -->
		<div class="layer-popup-container" v-show="visibleModal">
			<div class="layer-popup" id="modalProducts">
				<div class="popup-wrapper">
					<header class="popup-header">
						<h3 class="header-title">제품 정보</h3>
						<button type="button" class="btn-close" @click="closeModal">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body">
						<div class="flex popup-product-detail">
							<div class="thumb">
								<img :src="activeItem.imgUrl" :alt="activeItem.name" />
							</div>
							<div class="popup-product-info">
								<p class="popup-product-info__brand">{{ activeItem.business }}</p>
								<h5 class="popup-product-info__title">{{ activeItem.name }}</h5>
								<p class="popup-product-info__price">₩{{ numberCommaVal(activeItem.price) }}</p>
							</div>
						</div>
						<div class="popup-product-desc">{{ activeItem.cn }}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- e: 팝업 -->
	</section>
</template>

<script>
import { numberComma } from '../../../assets/js/utils';

export default {
	name: 'SelfDxResultProduct',
	components: {},
	props:{
		diagnPrdutDomains: {
			type: Array
			, default: []
		},
	},
	data: () => ({
		isVisible: false,
		visibleModal: false,
		activeItem: {},
	}),
	mounted() {
		this.$nextTick(() => {
			this.initializeIntrsObsrv();
		});
	},
	methods: {
		initializeIntrsObsrv() {
			const io = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach(async (entry) => {
						// 감지대상이 교차영역에 진입 할 경우
						if (entry.isIntersecting) {
							this.isVisible = true;
							// 교차영역에 진입한 후 감지대상을 관찰 중지
							observer.unobserve(entry.target);
						} else {
							this.isVisible = false;
						}
					});
				},
				{
					rootMargin: '0px',
					threshold: 0.1,
				}
			);
			const element = this.$refs.viewport;
			if (element) {
				io.observe(element);
			}
		},
		openModal(item) {
			this.visibleModal = true;
			this.activeItem = item;
		},
		closeModal() {
			this.visibleModal = false;
		},
		numberCommaVal(val) {
			if(!val) return 0
			try {
				return numberComma(val)
			} catch (error) {
				return 0
			}
		}
	},
};
</script>
