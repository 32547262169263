<template>
	<main class="page-container" id="selfdx-result">
		<div v-if="isLoading" class="dxloader-container" :class="{ 'is-active': isLoading }">
			<div class="dxloader-inner">
				<span class="dxloader"> <i></i><i></i><i></i><i></i> </span>
				<p class="dxloader-text">자가진단 분석 중입니다</p>
			</div>
		</div>
		<template v-else>
			<!-- s: main -->
			<self-dx-result-main :name="result.name" :profileUrl="result.profileUrl" :diagnAge="result.diagnAge" :diagnGender="result.diagnGender" :diagnAddr="result.diagnAddr" :diagnAddrDetail="result.diagnAddrDetail" :diagnSector="result.diagnSector" :diagnExper="result.diagnExper" :analyTchnlgy="result.analyTchnlgy" :analyOnline="result.analyOnline" :analyBncmrk="result.analyBncmrk" :analySrnst="result.analySrnst" :prompt="result.prompt" />
			<!-- e: main -->

			<div class="selfdx-content">
				<div class="container">
					<div class="selfdx-bg">
						<i class="icon icon-selfdx-bg1"></i>
					</div>
				</div>
				<section class="selfdx-section1" ref="viewport">
					<div class="container">
						<div class="selfdx-section1-header">
							<h2 class="selfdx-section1-header__title">회원님에게 필요한 정보를 AI가 추천드려요</h2>
							<p class="selfdx-section1-header__desc">다양한 콘텐츠를 통해 역량을 강화해 보세요</p>
						</div>
					</div>
					<div class="selfdx-section1-content">
						<!-- s: 판로지원을 원하는 소상공인을 위한 지원사업 추천 -->
						<self-dx-result-chat v-show="mssSprtBizContent" :mssSprtBizContent="mssSprtBizContent || ''" />
						<!-- e: 판로지원을 원하는 소상공인을 위한 지원사업 추천 -->

						<!-- s: 판로 지원 -->
						<self-dx-result-bizsupport v-show="result?.diagnAiRes?.results?.supp_proj?.length" :diagnSuppProjs="result?.diagnAiRes?.results?.supp_proj || []" />
						<!-- e: 판로 지원 -->

						<!-- s: 역량강화를 위한 콘텐츠 추천 -->
						<self-dx-result-panrotv v-show="result?.diagnAiRes?.results?.fanro_vod?.length" :diagnFanroVods="result?.diagnAiRes?.results?.fanro_vod || []" />
						<!-- e: 역량강화를 위한 콘텐츠 추천 -->

						<!-- s: 사례분석에 도움이 되는 우수제품 소개 -->
						<!-- <self-dx-result-product  v-show="result?.diagnPrdutDomains?.length" :diagnPrdutDomains="result?.diagnPrdutDomains || []" /> -->
						<!-- e: 사례분석에 도움이 되는 우수제품 소개 -->

						<!-- s: 무료 장비/스튜디오 대여 활용 -->
						<self-dx-result-sodam v-show="result?.diagnAiRes?.results?.studio_info?.length" :diagnStudioInfos="result?.diagnAiRes?.results?.studio_info || []" />
						<!-- e: 무료 장비/스튜디오 대여 활용 -->

						<!-- s: 다양한 정보를 볼 수 있는 소상공인뉴스 -->
						<self-dx-result-news v-show="result?.diagnAiRes?.results?.news_info?.length" :diagnNewsInfos="result?.diagnAiRes?.results?.news_info || []" />
						<!-- e: 다양한 정보를 볼 수 있는 소상공인뉴스 -->

						<!-- s: 정보습득을 위한 e러닝 추천 -->
						<self-dx-result-elearning v-show="result?.diagnAiRes?.results?.e_learning?.length" :diagnELearning="result?.diagnAiRes?.results?.e_learning || []" />
						<!-- e: 정보습득을 위한 e러닝 추천 -->
					</div>
				</section>
				<div class="selfdx-footer buttons">
					<button class="button-default is-medium is-rounded is-primary" @click="reDiagnSelfCheck">다시시작</button>
					<button v-if="isPrintVisible" class="button-default is-medium is-rounded is-secondary" @click="printPage">출력하기</button>
				</div>
			</div>
		</template>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_DIAGN_SELF_CHECK } from '@/store/_act_consts';
import { MUT_SHOW_ALERT, MUT_SET_RETURN_ROUTE } from '@/store/_mut_consts';
import { getTokenByKey } from '@/assets/js/jwt.service';
import { getItem } from '../../../assets/js/utils';
import { dateFormatYYYYMM } from '../../../assets/js/utils';

import SelfDxResultMain from './SelfDxResultMain';
import SelfDxResultChat from './SelfDxResultChat';
import SelfDxResultBizsupport from './SelfDxResultBizsupport';
import SelfDxResultPanrotv from './SelfDxResultPanrotv';
import SelfDxResultProduct from './SelfDxResultProduct';
import SelfDxResultSodam from './SelfDxResultSodam';
import SelfDxResultNews from './SelfDxResultNews';
import SelfDxResultElearning from './SelfDxResultElearning';

export default {
	name: 'SelfDiagnosisResult',
	components: {
		SelfDxResultMain,
		SelfDxResultChat,
		SelfDxResultBizsupport,
		SelfDxResultPanrotv,
		SelfDxResultProduct,
		SelfDxResultSodam,
		SelfDxResultNews,
		SelfDxResultElearning,
	},
	computed: {
		...mapGetters('auth', ['isAuth', 'session']),
	},
	props: {
		paramsJson: {
			type: String,
			reqired: true,
		},
	},
	data: () => ({
		mssSprtBizContent: false,
		isLoading: false,
		isVisible: false,
		params: {
			diagnGender: '',
			diagnAge: '',
			diagnSector: '',
			diagnSectorDetails: [],
			diagnExper: '',
			diagnAddr: '',
			diagnAddrDetail: '',
			diagnCmpnyScale: '',
			diagnSales: '',
			diagnCmpnyType: '',
			diagnCmpnyOfflineScore: 0,
			diagnCmpnyOnlineScore: 0,
			diagnDiffFields: [],
			manageScore: 0,
			marketingScore: 0,
			itemScore: 0,
			financeScore: 0,
			locationScore: 0,
			readyScore: 0,
			onlineSaleYn: '',
			onlineNowChnl: [],
			onlineNowTv: [],
			onlineNowMid: [],
			onlineNowLiv: [],
			onlineNowSub: [],
			onlineAditChnl: [],
			onlineAditTv: [],
			onlineAditMid: [],
			onlineAditLiv: [],
			onlineAditSub: [],
			onlineHopeChnl: [],
			onlineHopeTv: [],
			onlineHopeMid: [],
			onlineHopeLiv: [],
			onlineHopeSub: [],
			globalSaleYn: '',
			globalNowChnl: [],
			globalAditChnl: [],
			globalNowNation: [],
			globalAditNation: [],
			globalHopeChnl: [],
			globalHopeation: [],
		},
		result: {},
		isPrintVisible: false,
	}),
	created() {
		const tagetDate = new Date('2024-11-01 00:00:00');
		const nowDate = new Date();
		if (nowDate > tagetDate) {
			this.isPrintVisible = true;
		}

		Object.assign(this.params, JSON.parse(localStorage.getItem('diagnosisData')));

		this.$nextTick(() => {
			setTimeout(async () => {
				await this.isLoginCheck();
			}, 500);
		});
	},
	async mounted() {
		this.$nextTick(() => {
			document.querySelector('html').style.overflowX = 'hidden';
		});
		this.isLoading = true;
		await this.getDiagnSelfCheck();
		this.isLoading = false;
	},
	methods: {
		async isLoginCheck() {
			if (!getTokenByKey('auth_token')) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					subText: '*자가진단 서비스는&nbsp;<strong>통합회원 대상 서비스</strong>입니다.',
					html: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
				return false;
			} else {
				if (Number(this.session.ssoSeq) === 0 || Number(this.session.userPattnCd) !== 400) {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: '통합회원만 사용이 가능합니다.',
						html: true,
						yesfunc: () => {
							this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
							this.$router.push({ path: '/mypage2/home' });
						},
					});
					return false;
				}
			}
		},
		async getDiagnSelfCheck() {
			await this.$store
				.dispatch(`diagn/${ACT_GET_DIAGN_SELF_CHECK}`, this.params)
				.then((res) => {
					if (200 == res.status.code) {
						const item = getItem(res);
						this.result = item;

						this.mssSprtBizContent = '';
						if (this.result.mssSprtBizCmpnyDomains.length) {
							this.mssSprtBizContent += '다음과 같이 지원사업에 참여한 이력이 있으시네요.<br>';

							this.result.mssSprtBizCmpnyDomains.slice(0, 3).forEach((item) => {
								this.mssSprtBizContent += `- ${dateFormatYYYYMM(item.aplySttusRegYmd)} : ${item.sprtBizNm}<br>`;
							});
							this.mssSprtBizContent += '<br>분석 내용을 바탕으로 이런 지원사업을 추천해 드려요.';
						}
					}
				})
				.catch((err) => {
					console.error(err);
				});
		},
		reDiagnSelfCheck() {
			this.$router.push({ name: 'SelfDiagnosisStart' });
		},
		printPage() {
			window.print();
		},
	},
};
</script>
