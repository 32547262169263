<template>
	<section id="selfdxChat" class="selfdx-section is-box" :class="{ 'is-active': isVisible }" ref="viewport">
		<div class="container">
			<div class="selfdx-section-header">
				<h3 class="selfdx-section-header__title"><span class="color-orange">지원사업 참여이력</span> 확인</h3>
			</div>
			<div class="selfdx-section-content">
				<i class="selfdx-voice"><em></em><em></em><em></em></i>
				<p class="desc" v-if="showText"><text-typing :content="mssSprtBizContent" /></p>
			</div>
		</div>
	</section>
</template>

<script>
import { dateFormatYYYYMM } from '../../../assets/js/utils';
import TextTyping from '@/components/common/TextTyping';
export default {
	name: 'SelfDxResultChat',
	components: {},
	props: {
		mssSprtBizContent: {
			type: String,
			default: '',
		},
	},
	components: { TextTyping },
	data: () => ({
		isVisible: false,
		showText: false,
	}),
	mounted() {
		this.$nextTick(() => {
			this.initializeIntrsObsrv();
		});
	},
	methods: {
		initializeIntrsObsrv() {
			const io = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach(async (entry) => {
						// 감지대상이 교차영역에 진입 할 경우
						if (entry.isIntersecting) {
							this.isVisible = true;
							// setTimeout(() => {
							this.showText = true;
							// }, 1000);
							// 교차영역에 진입한 후 감지대상을 관찰 중지
							observer.unobserve(entry.target);
						} else {
							this.isVisible = false;
							this.showText = false;
						}
					});
				},
				{
					rootMargin: '0px',
					threshold: 0.1,
				}
			);
			const element = this.$refs.viewport;
			if (element) {
				io.observe(element);
			}
		},
	},
};
</script>
