<template>
	<section id="selfdxBizsupport" class="selfdx-section" :class="{ 'is-active': isVisible }" ref="viewport">
		<div class="selfdx-bg">
			<i class="icon icon-selfdx-bg2"></i>
			<i class="icon icon-selfdx-bg3"></i>
		</div>
		<div class="container">
			<div class="selfdx-section-header">
				<h3 class="selfdx-section-header__title">판로확대를 위한 <span class="color-orange">소상공인 지원사업 추천</span></h3>
				<p class="selfdx-section-header__desc">온라인 쇼핑몰 입점부터 상세 페이지 제작, 해외 쇼핑몰 및 직구까지 실질적인 판로 진출을 도와드려요.</p>
			</div>
			<div class="selfdx-section-content">
				<section v-for="(item, idx) in diagnSuppProjs" :key="idx" class="selfdx-result display-flex" :class="{ 'is-active': visibleArr.includes(Number(idx)) }">
					<div class="selfdx-result-header flex-left">
						<div class="selfdx-result-header__top">
							<span class="selfdx-result-header__tag">지원사업 추천 {{ idx + 1 }}</span>
						</div>
						<h4 class="selfdx-result-header__title">{{ item.suppProjName }}</h4>
						<p class="selfdx-result-header__desc">{{ item.suppDesc }}</p>
						<div class="buttons">
							<a :href="item.suppLink" target="_blank" class="button-default is-medium is-rounded is-secondary">지원사업 소개</a>
						</div>
					</div>
					<section class="flex-right" v-show="item.succ_case?.length">
						<!-- <h5 class="card-list-title">성공사례</h5> -->
						<ul class="card-list">
							<li v-for="(item1, idx1) in item.succ_case" :key="idx1" class="card-item">
								<a :href="item1.succLink" target="_blank" class="card-item__link">
									<span class="card-item__image">
										<img :src="item1.succThumbnail" :alt="item1.succProjName" />
									</span>
									<!-- <span class="card-item__text"> {{ item1.succProjName }}</span> -->
								</a>
							</li>
						</ul>
					</section>
				</section>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SelfDxResultBizSupport',
	components: {},
	props: {
		diagnSuppProjs: {
			type: Array,
			default: [],
		},
	},
	data: () => ({
		isVisible: false,
		visibleArr: [],
	}),
	mounted() {
		this.$nextTick(() => {
			this.initializeIntrsObsrv();
		});
	},
	methods: {
		initializeIntrsObsrv() {
			const element = this.$refs.viewport;
			if (element) {
				const io = new IntersectionObserver(
					(entries, observer) => {
						entries.forEach(async (entry) => {
							// 감지대상이 교차영역에 진입 할 경우
							if (entry.isIntersecting) {
								this.isVisible = true;
								// 교차영역에 진입한 후 감지대상을 관찰 중지
								observer.unobserve(entry.target);
							} else {
								this.isVisible = false;
							}
						});
					},
					{
						rootMargin: '0px',
						threshold: 0.1,
					}
				);
				io.observe(element);
			}

			try {
				const elementItems = this.$refs.viewport.querySelectorAll('.selfdx-result');
				if (elementItems.length > 0) {
					for (let i = 0; i < elementItems.length; i++) {
						const io = new IntersectionObserver(
							(entries, observer) => {
								entries.forEach(async (entry) => {
									// 감지대상이 교차영역에 진입 할 경우
									if (entry.isIntersecting) {
										this.visibleArr.push(i);
										// 교차영역에 진입한 후 감지대상을 관찰 중지
										observer.unobserve(entry.target);
									} else {
										this.visibleArr = this.visibleArr.filter((item) => item !== i);
									}
								});
							},
							{
								rootMargin: '0px',
								threshold: 0.1,
							}
						);
						io.observe(elementItems[i]);
					}
				}
			} catch (error) {}
		},
	},
};
</script>
