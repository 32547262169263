<template>
	<section class="selfdx-msection" :class="{ 'is-active': isVisible }">
		<div class="container" ref="viewport">
			<div class="print-only">
				<div class="selfdx-print-header flex align-items-center justify-content-between">
					<div class="fl">
						<i class="icon icon-selfdx-mss">
							<img src="~@/assets/img/selfdx/logo_mss.png" alt="중소벤처기업부" />
						</i>
						<i class="icon icon-selfdx-sbdc">
							<img src="~@/assets/img/selfdx/logo_sbdc.png" alt="중소기업유통센터" />
						</i>
						<i class="icon icon-selfdx-gachi">
							<img src="~@/assets/img/selfdx/logo_gachi.png" alt="가치삽시다" />
						</i>
					</div>
					<div class="fr">소상공인 온라인 진출지원 플랫폼(가치삽시다)의 "자가진단 서비스"를 통한 결과 화면으로 출력 되었습니다.</div>
				</div>
			</div>
			<div class="selfdx-msection-header">
				<h2 class="selfdx-msection-header__title animation-fade-up">{{ name }} 님의 자가진단 AI 분석이 완료 되었어요</h2>
				<p class="selfdx-msection-header__desc"><span class="animation-typing">결과표를 통해 맞춤형 지원을 확인해보세요</span></p>
			</div>
			<div class="selfdx-msection-content">
				<section class="selfdx-msection-subcontainer">
					<div class="selfdx-msection-subcontainer__header animation-fade-up">
						<i class="icon icon-selfdx-main-1"></i>
						<h3 class="selfdx-msection-subcontainer__title">자가진단 결과</h3>
					</div>
					<div class="display-flex">
						<div class="flex-l animation-fade-up">
							<section class="selfdx-msection-sub">
								<div class="selfdx-msection-sub__header">
									<i class="icon icon-selfdx-main-11"></i>
									<h4 class="selfdx-msection-sub__title">소상공인 기본정보</h4>
								</div>
								<div class="selfdx-msection-sub__body">
									<div class="selfdx-msection-sub__img">
										<img :src="profile" alt="" />
									</div>
									<div class="selfdx-msection-sub__info">
										<dl>
											<dt>대상</dt>
											<dd>{{ findEnums(arrAge, diagnAge) }} {{ findEnums(arrGender, diagnGender) }}</dd>
										</dl>
										<dl>
											<dt>지역</dt>
											<dd>{{ diagnAddr }} {{ diagnAddrDetail }}</dd>
										</dl>
										<dl>
											<dt>업종</dt>
											<dd>{{ findEnums(arrSector, diagnSector) }}</dd>
										</dl>
										<dl>
											<dt>연차</dt>
											<dd>{{ findEnums(arrExper, diagnExper) }}</dd>
										</dl>
									</div>
								</div>
							</section>
						</div>
						<div class="flex-r animation-fade-up">
							<section class="selfdx-msection-sub">
								<div class="selfdx-msection-sub__header">
									<i class="icon icon-selfdx-main-12"></i>
									<h4 class="selfdx-msection-sub__title">자가진단 수치</h4>
								</div>
								<div class="selfdx-msection-sub__body">
									<div class="selfdx-msection-sub__graph">
										<dl>
											<dt>경영능력 진단</dt>
											<dd>
												<div v-if="showGraph1">
													<transition name="fade">
														<transition<circular-progress-graph :value="String(analyTchnlgy)" color="#FBB635" />
													</transition>
												</div>
												<div v-else class="dxloader-small-container">
													<span class="dxloader-small"></span>
												</div>
											</dd>
										</dl>
										<dl>
											<dt>마케팅 역량 평가</dt>
											<dd>
												<div v-if="showGraph2">
													<transition name="fade">
														<circular-progress-graph :value="String(analyOnline)" color="#0EA7E6" />
													</transition>
												</div>
												<div v-else class="dxloader-small-container">
													<span class="dxloader-small"></span>
												</div>
											</dd>
										</dl>
										<dl>
											<dt>종합 사업성 평가</dt>
											<dd>
												<div v-if="showGraph3">
													<transition name="fade">
														<circular-progress-graph :value="String(analyBncmrk)" color="#EE5B42" />
													</transition>
												</div>
												<div v-else class="dxloader-small-container">
													<span class="dxloader-small"></span>
												</div>
											</dd>
										</dl>
										<dl>
											<dt>지원사업 활용도</dt>
											<dd>
												<div v-if="showGraph4">
													<transition name="fade">
														<circular-progress-graph :value="String(analySrnst)" color="#08D1DE" />
													</transition>
												</div>
												<div v-else class="dxloader-small-container">
													<span class="dxloader-small"></span>
												</div>
											</dd>
										</dl>
									</div>
									<div class="selfdx-msection-sub__desc" v-if="showText">
										<i class="selfdx-voice"><em></em><em></em><em></em></i>
										<text-typing :content="prompt" />
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
		</div>
	</section>
</template>

<script>
import CircularProgressGraph from '@/components/common/CircularProgressGraph';
import TextTyping from '@/components/common/TextTyping';
import { Gender, Age, Sector, Exper, SectorDetail } from '../../../store/modules/diagn';
export default {
	name: 'SelfDxResultMain',
	props: {
		name: {
			type: String,
			default: '',
		},
		profileUrl: {
			type: String,
			default: '',
		},
		diagnAge: {
			type: String,
			default: '',
		},
		diagnGender: {
			type: String,
			default: '',
		},
		diagnAddr: {
			type: String,
			default: '',
		},
		diagnAddrDetail: {
			type: String,
			default: '',
		},
		diagnSector: {
			type: String,
			default: '',
		},
		diagnExper: {
			type: String,
			default: '',
		},
		analyTchnlgy: {
			type: Number,
			default: 0,
		},
		analyOnline: {
			type: Number,
			default: 0,
		},
		analyBncmrk: {
			type: Number,
			default: 0,
		},
		analySrnst: {
			type: Number,
			default: 0,
		},
		prompt: {
			type: String,
			default: '',
		},
	},
	computed: {
		profile() {
			if (this.profileUrl) {
				return require(`@/assets/img/selfdx/${this.profileUrl}`);
			}
		},
	},
	components: { CircularProgressGraph, TextTyping },
	data: () => ({
		isVisible: false,
		showGraph1: false,
		showGraph2: false,
		showGraph3: false,
		showGraph4: false,
		showText: false,
		arrAge: Age,
		arrGender: Gender,
		arrSector: Sector,
		arrSectorDetail: SectorDetail,
		arrExper: Exper,
	}),
	mounted() {
		setTimeout(() => {
			this.showGraph1 = true;
		}, 2500);
		setTimeout(() => {
			this.showGraph2 = true;
		}, 3000);
		setTimeout(() => {
			this.showGraph3 = true;
		}, 3500);
		setTimeout(() => {
			this.showGraph4 = true;
		}, 4000);
		setTimeout(() => {
			this.showGraph4 = true;
		}, 4500);
		setTimeout(() => {
			this.showText = true;
		}, 5000);
	},
	methods: {
		findEnums(arr, val) {
			return arr.find((item) => item.value === val)?.label;
		},
	},
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
